.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 70vh;
}

.title {
  font-family: var(--font-family-headings);

  @media (--viewport-m) {
    font-size: var(--font-size-xxxl);
  }
}

.subtitle {
  font-family: var(--font-family-headings);
}

.text {
  color: var(--color-body);
}

.buttonContainer {
  margin-top: 4rem;

  @media (--viewport-m) {
    margin-top: 8rem;
  }
}
